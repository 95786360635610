import React, { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

const InputButton = forwardRef(
    ({ hasError, className, children, isPlaceholder, ...props }, ref) => (
        <button
            ref={ref}
            type="button"
            className={twMerge([
                'border py-3 px-4 w-full rounded text-sm leading-[22px] text-left focus:outline-default-input',
                'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:hover:border-gray-100',
                !hasError && 'border-gray-100',
                hasError && 'border-primary',
                isPlaceholder && 'text-gray-200',
                className,
            ])}
            {...props}
        >
            {children}
        </button>
    )
)

export default InputButton

import React from 'react'
import { twMerge } from 'tailwind-merge'
import Icon from './icon'

function Spinner({ className = '', size, color, ...props }) {
    return (
        <Icon
            name="spinner"
            className={twMerge('animate-spin-slow', className)}
            size={size}
            color={color}
            {...props}
        />
    )
}

export default Spinner

import { twMerge } from 'tailwind-merge'
import Radio from './radio'

export default function RadioCard({
    value,
    title,
    onChange,
    children,
    disabled,
    className,
    ...props
}) {
    return (
        <div
            role="radio"
            aria-checked={value}
            tabIndex={disabled ? -1 : 0}
            onClick={(e) => !disabled && onChange(e)}
            className={twMerge(
                'border border-gray-100 rounded-md p-4 flex flex-col gap-2',
                value && 'border-blue-bright',
                disabled
                    ? 'opacity-70 cursor-not-allowed'
                    : 'cursor-pointer focus:ring-2 focus-visible:ring-2 ring-blue-bright',
                className
            )}
            onKeyDown={(e) => {
                if (!disabled) {
                    switch (e.code) {
                        case 'Space':
                        case 'Enter':
                            e.preventDefault()
                            onChange(e)
                            break
                        default:
                            break
                    }
                }
            }}
        >
            <div className="flex items-start gap-2">
                <Radio
                    readOnly
                    value={value}
                    tabIndex={-1}
                    disabled={disabled}
                    className="flex-shrink-0"
                    {...props}
                />
                <span className="font-medium text-sm">{title}</span>
            </div>
            {children}
        </div>
    )
}

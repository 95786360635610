import React from 'react'
import { addMonths, isEqual, getMonth, isSameMonth } from 'date-fns'
import { twMerge } from 'tailwind-merge'
import useFormatDate from './hooks/useFormatDate'
import Icon from './icon'

const DateCalendarMonthButtons = ({
    numberOfMonths = 10,
    firstDayFirstMonth = null,
    firstDaySecondMonth = null,
    onClickMonth,
    onClickPreviousMonth,
    onClickNextMonth,
}) => {
    const format = useFormatDate()
    return (
        <div className="flex justify-between items-center mb-4">
            <button
                type="button"
                className="flex items-center justify-center w-9 h-9"
                onClick={() => onClickPreviousMonth()}
            >
                <Icon name="chevron-left" />
            </button>
            <div className="flex mx-4">
                {Array.from(Array(numberOfMonths).keys()).map((index) => {
                    const hasMoreThanOneMonth =
                        firstDayFirstMonth && firstDaySecondMonth
                    const firstDay = addMonths(firstDayFirstMonth, index)
                    const isThisMonth = isSameMonth(firstDay, new Date())
                    const isFirstSelected = isEqual(
                        firstDay,
                        firstDayFirstMonth
                    )
                    const isSecondSelected = isEqual(
                        firstDay,
                        firstDaySecondMonth
                    )
                    const monthIsSelected = isFirstSelected || isSecondSelected
                    return (
                        <button
                            key={firstDay.toISOString()}
                            type="button"
                            className={twMerge(
                                'p-2 text-sm flex-1 border border-transparent',
                                isThisMonth && 'border-gray-400',
                                !monthIsSelected &&
                                    'rounded-lg hover:bg-gray-100',
                                monthIsSelected && 'bg-gray-100',
                                !hasMoreThanOneMonth && 'rounded-lg',
                                hasMoreThanOneMonth &&
                                    isFirstSelected &&
                                    'rounded-l-lg',
                                hasMoreThanOneMonth &&
                                    isSecondSelected &&
                                    'rounded-r-lg'
                            )}
                            onClick={() => {
                                if (!isFirstSelected) {
                                    const monthIndex = getMonth(firstDay)
                                    const firstMonthIndex =
                                        getMonth(firstDayFirstMonth)
                                    const secondMonthIndex =
                                        getMonth(firstDaySecondMonth)
                                    const hasSelectedNextYear =
                                        monthIndex < firstMonthIndex ||
                                        monthIndex < secondMonthIndex
                                    if (hasSelectedNextYear) {
                                        onClickMonth(monthIndex, firstDay)
                                    } else {
                                        onClickMonth(monthIndex)
                                    }
                                    onClickMonth(getMonth(firstDay))
                                }
                            }}
                            data-testid={`CalendarMonth-${format(
                                firstDay,
                                'M'
                            )}`}
                        >
                            {format(firstDay, 'MMM')}
                        </button>
                    )
                })}
            </div>
            <button
                type="button"
                className="flex items-center justify-center w-9 h-9"
                onClick={() => onClickNextMonth()}
            >
                <Icon name="chevron-right" />
            </button>
        </div>
    )
}

export default DateCalendarMonthButtons

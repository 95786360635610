import React from 'react'
import { twMerge } from 'tailwind-merge'
import Icon from './icon'

const SwitchButton = ({ onClick, className }) => (
    <button
        type="button"
        className={twMerge(
            className,
            'border-gray-100 border rounded-full w-8 h-8 bg-white flex items-center justify-center text-gray-400',
            'transition-colors hover:bg-gray-50 hover:text-gray-600'
        )}
        onClick={onClick}
    >
        <Icon name="exchange-alt" />
    </button>
)

export default SwitchButton

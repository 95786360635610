import React from 'react'
import { twMerge } from 'tailwind-merge'
import Icon from './icon'

function Avatar({
    className,
    imageUrl = null,
    iconName = 'user',
    iconClassName,
    iconProps,
    ...props
}) {
    return (
        <span
            className={twMerge(
                'inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100 p-2 bg-cover',
                className
            )}
            style={{ backgroundImage: `url(${imageUrl})` }}
            {...props}
        >
            {imageUrl === null && (
                <Icon
                    name={iconName}
                    className={twMerge('h-full w-full', iconClassName)}
                    {...iconProps}
                />
            )}
        </span>
    )
}

export function AvatarLoading() {
    return <div className="h-10 w-10 bg-gray-200 rounded-full skeleton" />
}

export default Avatar

'use client'

import { createPortal } from 'react-dom'
import { useEffect, useState } from 'react'

export default function Portal({ children, querySelector }) {
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        setMounted(true)
        return () => setMounted(false)
    }, [])

    if (!mounted) {
        return null
    }

    return createPortal(children, document.querySelector(querySelector))
}

import React from 'react'
import { twMerge } from 'tailwind-merge'

export default function Checkbox({ value, hasError, className, ...props }) {
    return (
        <input
            type="checkbox"
            checked={value}
            className={twMerge(
                'h-4 w-4 text-blue-bright border-gray-200 rounded cursor-pointer',
                'disabled:opacity-40',
                className
            )}
            {...props}
        />
    )
}

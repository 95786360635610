'use client'

import React, { forwardRef } from 'react'
import { variant } from '@connections/utils'
import { twMerge } from 'tailwind-merge'

const ROUNDING_ALL = 'all'
const ROUNDING_LEFT = 'left'
const ROUNDING_RIGHT = 'right'
const ROUNDING_TOP = 'top'
const ROUNDING_BOTTOM = 'bottom'

const Input = forwardRef(
    (
        {
            value,
            hasError,
            className,
            isLoading,
            iconAfter,
            iconBefore,
            placeholder,
            as = 'input',
            inputClassName,
            rounding = ROUNDING_ALL,
            ...props
        },
        ref
    ) => {
        const Component = as
        return (
            <div
                className={twMerge('relative flex flex-col w-full', className)}
            >
                {iconBefore && (
                    <div className="absolute top-3.5 left-3">{iconBefore}</div>
                )}
                {iconAfter && (
                    <div className="absolute top-3.5 right-3">{iconAfter}</div>
                )}
                {isLoading && (
                    <div className="absolute skeleton w-full h-full" />
                )}
                <Component
                    ref={ref}
                    value={value}
                    placeholder={placeholder}
                    className={twMerge([
                        'min-h-[3rem] border py-3 w-full text-sm leading-[22px] placeholder-gray-400 text-black',
                        'disabled:bg-gray-50 disabled:cursor-not-allowed disabled:hover:border-gray-100',
                        hasError && 'border-primary hover:border-primary-dark',
                        !hasError && 'border-gray-100 hover:border-gray-300',
                        iconBefore ? 'pl-8' : 'pl-4',
                        iconAfter ? 'pr-8' : 'pr-4',
                        variant(rounding, {
                            [ROUNDING_ALL]: 'rounded-md',
                            [ROUNDING_TOP]: 'rounded-t-md',
                            [ROUNDING_BOTTOM]: 'rounded-b-md',
                            [ROUNDING_LEFT]: 'rounded-l-md',
                            [ROUNDING_RIGHT]: 'rounded-r-md',
                        }),
                        inputClassName,
                    ])}
                    {...props}
                />
                {as !== 'input' && typeof as !== 'function' && (
                    <div
                        className={twMerge(
                            'min-h-[1.5rem] absolute flex flex-col justify-center top-3.5',
                            iconBefore
                                ? 'w-[calc(100%-3.5rem)] left-11'
                                : 'w-[calc(100%-1.5rem)] left-3'
                        )}
                    >
                        {value || placeholder}
                    </div>
                )}
            </div>
        )
    }
)

export default Input

export default function Price({
    prefix,
    price = null,
    currencySymbol = '€',
    ...props
}) {
    if (price === null) {
        return ''
    }

    return (
        <span {...props}>
            {prefix && `${prefix} `}
            {currencySymbol}
            {price}
        </span>
    )
}

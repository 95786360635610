'use client'

import React, { useEffect, useRef } from 'react'
import { compareAsc, isFirstDayOfMonth } from 'date-fns'
import { useOnClickOutside } from '@wappla/react-hooks'
import useCalendar from './hooks/useCalendar'
import Button from './button'
import Calendar from './calendar'
import DateButton from './dateButton'
import DateCalendarMonthButtons from './dateCalendarMonthButtons'
import { twMerge } from 'tailwind-merge'

const DateCalendar = ({
    date,
    viewDate,
    format = 'EEEEEE dd MMM',
    className,
    inputClassName,
    numberOfMonthButtons = 7,
    dayIsDisabledCondition,
    placeholder,
    labelReset = 'Reset',
    labelToday = 'Today',
    onChange,
    onClickOutside = () => {},
    testId,
}) => {
    const inputRef = useRef()
    const containerRef = useRef()
    useEffect(() => inputRef.current.focus(), [])
    useOnClickOutside(containerRef, onClickOutside)
    const {
        calendar,
        deselect,
        inRange,
        isSelected,
        select,
        selected,
        selectRange,
        viewing,
        viewMonth,
        viewNextMonth,
        viewPreviousMonth,
        viewToday,
        clearSelected,
    } = useCalendar({
        viewing: date || viewDate,
        weekStartsOn: 1,
        numberOfMonths: 1,
        selected: [date],
    })
    const selectedSorted = selected.sort((a, b) => compareAsc(a, b))
    const [month] = calendar
    const allDays = month.flatMap((week) => week)
    const firstDay = allDays.find(isFirstDayOfMonth)
    const [firstSelected = null] = selectedSorted

    useEffect(() => {
        onChange(firstSelected)
    }, [firstSelected])

    return (
        <div
            ref={containerRef}
            className={twMerge('bg-white', className)}
            data-testid={testId}
        >
            <div className="lg:flex lg:justify-between">
                <DateButton
                    ref={inputRef}
                    date={date}
                    format={format}
                    className={inputClassName}
                    placeholder={placeholder}
                />
                <div className="flex justify-center">
                    <Button
                        variant="link"
                        className="text-blue-bright"
                        iconBeforeName="calendar"
                        onClick={viewToday}
                    >
                        {labelToday}
                    </Button>
                    <Button
                        variant="link"
                        className="text-blue-bright"
                        iconBeforeName="rotate"
                        onClick={clearSelected}
                    >
                        {labelReset}
                    </Button>
                </div>
            </div>

            <div className={twMerge('mt-4 w-full')}>
                <DateCalendarMonthButtons
                    firstDayFirstMonth={firstDay}
                    numberOfMonths={numberOfMonthButtons}
                    onClickMonth={viewMonth}
                    onClickNextMonth={viewNextMonth}
                    onClickPreviousMonth={viewPreviousMonth}
                />
                <Calendar
                    month={month}
                    inRange={inRange}
                    isSelected={isSelected}
                    selected={selectedSorted}
                    selectRange={selectRange}
                    dayIsDisabledCondition={dayIsDisabledCondition}
                    viewing={viewing}
                    viewNextMonth={viewNextMonth}
                    viewPreviousMonth={viewPreviousMonth}
                    viewToday={viewToday}
                    onSelectDay={select}
                    onDeselectDay={deselect}
                    onClickDay={(day) => {
                        clearSelected()
                        select(day)
                    }}
                    className="mb-8"
                />
            </div>
        </div>
    )
}

export default DateCalendar

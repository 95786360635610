import { LANGUAGE_EN, LANGUAGE_FR_BE } from '@connections/constants'
import { format } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { nl, fr, enUS } from 'date-fns/locale'

const getLocalData = (locale) => {
    if (locale === LANGUAGE_FR_BE) {
        return fr
    }
    if (locale === LANGUAGE_EN) {
        return enUS
    }
    return nl
}

const useFormatDate =
    (locale, options = {}) =>
    (date, formatString) => {
        const { inTimeZone = false, ...rest } = options
        const finalOptions = {
            locale: getLocalData(locale),
            ...rest,
        }
        if (inTimeZone) {
            return formatInTimeZone(date, 'UTC', formatString, finalOptions)
        }
        return format(date, formatString, finalOptions)
    }

export default useFormatDate

import React from 'react'
import { twMerge } from 'tailwind-merge'
import Radio from './radio'

const RadioInput = ({ id, className, children, disabled, ...props }) => (
    <label
        htmlFor={id}
        className={twMerge([
            'inline-flex items-start cursor-pointer',
            disabled && 'text-gray-300 cursor-not-allowed',
            !disabled && 'text-black',
            className,
        ])}
    >
        <Radio className="mr-2" disabled={disabled} id={id} {...props} />
        {children}
    </label>
)

export default RadioInput

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { twMerge } from 'tailwind-merge'
import Icon from './icon'
import Tooltip from './tooltip'

const PassengersCountInput = ({
    info,
    value,
    title,
    subTitle,
    minValue = 0,
    maxValue,
    onChange,
    className,
    testId = 'PassengersCountInput',
    ...props
}) => {
    const plusIsDisabled = value >= maxValue
    const minusIsDisabled = value <= minValue
    return (
        <div
            className={twMerge([
                'flex justify-between items-center py-3',
                className,
            ])}
            {...props}
        >
            <div>
                <label className="text-sm mb-[2px] flex gap-1 items-center">
                    {title}
                    {info && (
                        <Tooltip content={info} event="hover">
                            <Icon
                                size="smaller"
                                name="info-circle"
                                className="text-gray-200"
                            />
                        </Tooltip>
                    )}
                </label>
                <div className="text-xs text-gray-400">{subTitle}</div>
            </div>
            <div className="flex items-center">
                <button
                    type="button"
                    className={twMerge(
                        'p-2 text-gray-200',
                        minusIsDisabled
                            ? 'opacity-50 cursor-not-allowed'
                            : 'hover:text-blue-bright'
                    )}
                    onClick={() => onChange(value - 1)}
                    disabled={minusIsDisabled}
                    data-testid={`${testId}Minus`}
                >
                    <Icon name="circle-minus" size="small" />
                </button>
                <div
                    className="w-4 flex justify-center items-center"
                    data-testid={testId}
                >
                    {value}
                </div>
                <button
                    type="button"
                    className={twMerge(
                        'p-2 text-gray-200',
                        plusIsDisabled
                            ? 'opacity-50 cursor-not-allowed'
                            : 'hover:text-blue-bright'
                    )}
                    onClick={() => onChange(value + 1)}
                    disabled={plusIsDisabled}
                    data-testid={`${testId}Plus`}
                >
                    <Icon name="circle-plus" size="small" />
                </button>
            </div>
        </div>
    )
}

export default PassengersCountInput

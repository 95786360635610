import React from 'react'
import { twMerge } from 'tailwind-merge'
import Checkbox from './checkbox'

export default function FormCheckboxInput({
    id,
    children,
    disabled,
    className,
    ...props
}) {
    return (
        <label
            htmlFor={id}
            className={twMerge([
                'inline-flex items-start cursor-pointer',
                disabled && 'cursor-not-allowed',
                className,
            ])}
        >
            <Checkbox id={id} className="mr-2" disabled={disabled} {...props} />
            {children}
        </label>
    )
}

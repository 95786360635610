import React from 'react'

export default function FormInputErrors({ errors = [] }) {
    if (errors.length === 0) {
        return null
    }

    const [error] = errors
    return (
        <p
            data-testid="FormInputError"
            className="text-sm text-red-600 text-left mt-1"
        >
            {error}
        </p>
    )
}

import React from 'react'

export default function FormGroup({
    label,
    htmlFor,
    children,
    className,
    description = null,
    isRequired = false,
}) {
    return (
        <label className={className} htmlFor={htmlFor}>
            <span className="block mb-2 text-sm text-left">
                {label}
                {typeof label !== 'undefined' && isRequired === true && (
                    <span className="ml-2 text-primary">*</span>
                )}
            </span>
            {children}
            {description && (
                <p className="mt-1 text-sm text-gray-400" id={htmlFor}>
                    {description}
                </p>
            )}
        </label>
    )
}

import React from 'react'
import { twMerge } from 'tailwind-merge'

const Radio = React.forwardRef(({ className, value, ...props }, ref) => (
    <input
        ref={ref}
        type="radio"
        checked={value}
        className={twMerge(
            'h-4 w-4 cursor-pointer text-blue-bright border-gray-200',
            'disabled:opacity-40 disabled:cursor-not-allowed',
            className
        )}
        {...props}
    />
))

export default Radio

import React, { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import Icon from './icon'

const SelectButton = forwardRef(
    (
        {
            onClear,
            disabled,
            hasError,
            children,
            className,
            isPlaceholder,
            ...props
        },
        ref
    ) => (
        <button
            ref={ref}
            type="button"
            disabled={disabled}
            className={twMerge([
                'flex justify-between items-center border py-3 pl-4 pr-2 w-full rounded text-sm leading-[22px] text-left focus:outline-default-input',
                'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:hover:border-gray-100',
                isPlaceholder && 'text-gray-200',
                hasError
                    ? 'border-primary'
                    : 'border-gray-100 hover:border-gray-300',
                className,
            ])}
            {...props}
        >
            <span className="w-10/12 truncate">{children}</span>
            {(() => {
                if (disabled) {
                    return null
                }

                if (onClear) {
                    return (
                        <span
                            role="button"
                            tabIndex={0}
                            onClick={onClear}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    onClear()
                                }
                            }}
                            className="cursor-pointer"
                        >
                            <Icon
                                size="smaller"
                                color="black"
                                name="cross"
                                className="m-0.5"
                            />
                        </span>
                    )
                }

                return <Icon size="small" color="black" name="chevron-down" />
            })()}
        </button>
    )
)

export default SelectButton
